<template>
  <div v-loading="typesLoading" class="flex flex-col min-h-full">
    <div class="container py-24">
      <div class="text-center">
        <h2 class="text-3xl">{{ title }}</h2>
        <p class="mt-4 text-lg text-gray">{{ info }}</p>
      </div>
      <div>
        <div v-if="unitRows?.length" class="mt-12">
          <p class="text-primary text-2xl text-center">請選擇考試單位</p>
          <div class="flex flex-wrap px-12 md:px-0 mt-4 justify-center">
            <div
              v-for="row in unitRows"
              :key="row.id"
              class="btn"
              :class="{ active: row.id == $route.query.unitId }"
              @click="selectUnit(row.id)"
            >
              {{ row.title }}
            </div>
          </div>
        </div>
        <div v-if="subRows?.length" class="mt-12">
          <p class="text-primary text-2xl text-center">請選擇一個科目</p>
          <div class="flex flex-wrap px-12 md:px-0 mt-4 justify-center">
            <div
              v-for="row in subRows"
              :key="row.id"
              class="btn"
              :class="{ active: row.id == $route.query.subId }"
              @click="selectSub(row.id)"
            >
              {{ row.title }}
            </div>
          </div>
        </div>
      </div>
      <div v-loading="planLoading" class="mt-12">
        <template v-if="plansRows.length">
          <p class="text-primary text-2xl text-center">請選擇方案天數</p>
          <div class="flex flex-wrap px-12 md:px-0 mt-4 justify-center">
            <div v-for="row in plansRows" :key="row.id">
              <div
                class="btn"
                :class="{ active: row.id == form.planId }"
                @click="setPlanId(row.id)"
              >
                <div class="flex flex-col items-center space-y-2">
                  <div class="flex items-end px-4">
                    <span class="text-4xl font-bold">{{ row.dayCnt }} </span>
                    <span>天</span>
                  </div>
                  <hr class="w-full" />
                  <div class="">
                    <span
                      v-if="row.oriPrice != row.payPrice"
                      class="line-through text-xs opacity-50 mr-2"
                    >
                      {{ row.oriPrice }}</span
                    >
                    <span> {{ row.payPrice }}</span>
                    元
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
    <div
      class="w-full shadow bg-white py-4 flex justify-center items-center mt-auto sticky bottom-0"
    >
      <a
        href="#"
        class="btn-white"
        @click.prevent="$router.push({ name: 'Shop.Home' })"
        >上一步
      </a>
      <el-button
        href="#"
        :disabled="!form.planId"
        class="btn-primary"
        @click.prevent="
          $router.push({
            name: 'Shop.Checkout',
            query: { ...$route.query },
          })
        "
        >下一步
      </el-button>
    </div>
  </div>
</template>
<script setup>
import { inject, reactive, computed } from 'vue';

import { useRoute, useRouter } from 'vue-router';
const router = useRouter();
const route = useRoute();

const { form, setPlanId } = inject('shopData');
const { rows: typesRows, loading: typesLoading } = inject('typesData');

const unitRows = computed(() => {
  return typesRows.find((x) => x.id == route.query.typeId)?.children;
});
const subRows = computed(() => {
  return unitRows.value?.find((x) => x.id == route.query.unitId)?.children;
});
const selectUnit = (_id) => {
  router.push({
    query: { ...route.query, unitId: _id, subId: '' },
  });
};

const selectSub = (_id) => {
  // form.planId = _id;
  router.push({ query: { ...route.query, subId: _id } });
};
const {
  rows: plansRows,
  title,
  info,
  fetcher,
  loading: planLoading,
} = inject('plansData');
</script>

<style lang="sass" scoped>
:deep(.btn)
    @apply rounded-lg border px-8 py-5 bg-primary-light text-gray duration-300 cursor-pointer mx-2 mb-3
    &:hover,&.active
        @apply bg-info text-white shadow border-transparent
</style>
